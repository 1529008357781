<template>
    <v-container>
        <div class="chart_Layout"
            v-if="trendData1.watt && trendData1.watt.length > 1">
            <div class="mb-1"> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">전력량</span>
            </div>
            <Bar
                id="wattChart"
                ref="wattChart"
                :chart-options="options"
                :chart-data="chartWatt"
                :gradient="['#ffbe88', '#ff93df']"
                :rounding="6" 
                :height="180" 
            />
        </div>
    
        <div class="chart_Layout"
            v-if="trendData1.temp && trendData1.temp.length > 1">
            <div class="mb-1"> 
                <button class="reset_btn mr-1" @click="resetGraph()">
                    <v-icon color="white" class="mdi-18px">
                        mdi-refresh
                    </v-icon>
                </button>
                <span class="title">기온</span>
            </div>
            <LineChartGenerator
                id="tempChart"
                ref="tempChart"
                :chart-options="options"
                :chart-data="chartTemp"
                :height="180" 
            />
        </div>

        <div class="chart_Layout"
        v-if="trendData1.hum && trendData1.hum.length > 1">
        <div class="mb-1"> 
            <button class="reset_btn mr-1" @click="resetGraph()">
                <v-icon color="white" class="mdi-18px">
                    mdi-refresh
                </v-icon>
            </button>
            <span class="title">습도</span>
        </div>
        <LineChartGenerator
            id="humChart"
            ref="humChart"
            :chart-options="options"
            :chart-data="chartHum"
            :height="180" 
        />
        </div>

        <!-- <div class="chart_Layout"
        v-if="trendData1.pres && trendData1.pres.length > 1">
        <div class="mb-1"> 
            <button class="reset_btn mr-1" @click="resetGraph()">
                <v-icon color="white" class="mdi-18px">
                    mdi-refresh
                </v-icon>
            </button>
            <span class="title">기압</span>
        </div>
        <LineChartGenerator
            id="presChart"
            ref="presChart"
            :chart-options="options"
            :chart-data="chartPres"
            :height="180" 
        />
    </div> -->

    <!-- <div class="chart_Layout"
        v-if="trendData1.wind && trendData1.wind.length > 1">
        <div class="mb-1"> 
            <button class="reset_btn mr-1" @click="resetGraph()">
                <v-icon color="white" class="mdi-18px">
                    mdi-refresh
                </v-icon>
            </button>
            <span class="title">풍속</span>
        </div>
        <LineChartGenerator
            id="windChart"
            ref="windChart"
            :chart-options="options"
            :chart-data="chartWind"
            :height="180" 
        />
    </div> -->

        <!-- 일별 -->
        <div class="chart_Layout"
            v-if="trendData1.watt_daily && trendData1.watt_daily.length > 1">
            <span class="title">전력량</span>
            <Bar
                :chart-options="options"
                :chart-data="dailyChartWatt"
                :height="180"
            />
        </div>
    
        <div class="chart_Layout"
            v-if="trendData1.temp_avg && trendData1.temp_avg.length > 1">
            <span class="title">기온</span>
            <LineChartGenerator
                :chart-options="Dailyoptions"
                :chart-data="dailyChartTemp"
                :height="180"
            />
        </div>

        <div class="chart_Layout"
        v-if="trendData1.hum_avg && trendData1.hum_avg.length > 1">
        <span class="title">습도</span>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartHum"
            :height="180"
        />
    </div>

    <!-- <div class="chart_Layout"
        v-if="trendData1.pres_avg && trendData1.pres_avg.length> 1">
        <span class="title">기압</span>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartPres"
            :height="180"
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData1.wind_avg && trendData1.pres_avg.length> 1">
        <span class="title">풍속</span>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartWind"
            :height="180"
        />
    </div> -->
    </v-container>
    </template>
    
    <script>
    import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
    import { Bar } from 'vue-chartjs/legacy'
    import zoomPlugin from 'chartjs-plugin-zoom';
    
    import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    BarElement,
    CategoryScale,
    PointElement,
    Filler
    } from 'chart.js'
    
    ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    BarElement,
    CategoryScale,
    PointElement,
    Filler,
    zoomPlugin
    )
    
    export default {
    name: 'WattChart',
    
    components: {
        LineChartGenerator,
        Bar
    },
    
    props: {
        trendData1: {
        type: Object,
        },
    },
    
    data: () => ({
        chartWatt: { },
        chartTemp: { },
        chartHum: { },
        // chartPres : { },
        // chartWind : { },

        dailyChartWatt: { },
        dailyChartTemp: { },
        dailyChartHum: { },
        // dailyChartPres : { },
        // dailyChartWind : { },
        
        options: {
            elements: {
                line: {	/* 선 옵션 */
                        borderCapStyle: 'round',
                        borderDashOffset: 0,
                        borderJoinStyle: 'round',	
                        borderWidth: 2.5,
                        capBezierPoints: true,
                        fill: true,
                        tension: 0.4
                    },
                    point: {	/* 표시자 옵션 */
                        pointStyle: 'rectRounded',
                        pointRadius: 1, 
                        lineTension:1, 
                        pointBorderWidth: 0, 
                    },
            },
            plugins: {
                zoom: {
                        zoom: {
                            onZoomComplete({chart}) {
                            for (const k of Object.keys(ChartJS.instances)) {
                                if (k !== chart.id) {
                                    const c = ChartJS.instances[k];
                                    c.config.options.scales.x.min = Math.trunc(chart.scales.x.min);
                                    c.config.options.scales.x.max = Math.trunc(chart.scales.x.max);
                                    c.update();
                                }
                            }},
                        // 전체 동기화 zoom end
                        drag: {
                            enabled: true
                        },
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        },
                        animation: {
                            duration: 1000,
                            easing: 'easeOutCubic'
                        }
                },
                legend: {
                display: false,
                position: "top",
                labels: {
                        boxWidth: 1,
                        padding: 1,
                        // usePointStyle: true,
                        pointStyle: "circle",
                        font: {
                        size: 13
                        }
                },
                fullSize: true,
                align: "left"
                },
                tooltip: {
                backgroundColor: '#ACB8C0',
                caretSize: 4,
                cornerRadius: 6,
                borderWidth: 0,
                bodyFont: {
                        size: 12
                }
                },
                hover: {
                    animationDuration: 400,
                    axis: 'x',
                    intersect: true,
                    mode: 'label',
                    onHover: null
                },
            },
            scales: {
                x: {
                grid: {
                        display: false
                },
                ticks: {
                        autoSkip: true,
                        maxTicksLimit:5,
                        maxRotation: 0,
                        minRotation: 0,
                        display:true,
                        padding: 3,
                        font: {
                        size: 9,
                        }
                },
                },
                y: {
                afterFit(scale) {       // ticks 표시영역 폭 통일 
                        scale.width = 50;
                },
                ticks: {
                        padding: 5,
                        font: {
                        size: 12,
                        }
                },
                // y축 스타일 지정
                grid: {
                drawBorder: false,
                color: "#e5e5e5",
                lineWidth: 1
                },
                fontSize:9,
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 1000
            }
        },
    
        Dailyoptions: {
            elements: {
                line: {
                borderCapStyle: 'round',	/* 선 마감: butt, round, square */
                borderDashOffset: 5,
                borderJoinStyle: 'round',	/* 꺾이는 부분 모양: miter, bevel, round */
                borderWidth: 3,
                capBezierPoints: true,
                fill: true,
                tension: 0.4
                },
                point: {
                pointStyle: 'circle',
                pointRadius: 3, //point 없게 만들기 0으로 설정
                pointBorderWidth: 1, //포인터 보더사이즈
                },
            },
            plugins: {
                legend: {
                    display: false,
                    fullSize: false,
                },
                tooltip: {
                    backgroundColor: '#ACB8C0',
                    caretSize: 4,
                    cornerRadius: 6,
                    borderWidth: 0,
                    bodyFont: {
                            size: 12
                    }
                },
                hover: {
                    animationDuration: 400,
                    axis: 'x',
                    intersect: true,
                    mode: 'label',
                    onHover: null
                },
            },
            scales: {
                x: {
                grid: {
                        display: true
                },
                ticks: {
                        display:true,
                        padding: 3,
                        font: {
                        size: 9,
                        }
                },
                },
                y: {
                ticks: {
                        padding: 5,
                        font: {
                        size: 12,
                        }
                },
                // y축 스타일 지정
                grid: {
                drawBorder: false,
                color: "#e5e5e5",
                lineWidth: 1
                },
                fontSize:9,
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 1000
            }
        },
    }),
    watch:{
        trendData1: function(){
            // 시간별
            this.chartWatt = {
                    labels: this.trendData1.watt_time,
    
                    datasets:[{
                        type:'bar',
                        label:'전력량',
                        data: this.trendData1.watt,
                        borderColor: '#F7F2A9',
                        backgroundColor: "rgba(247, 242, 169, 1.0)",
                        // borderWidth: 0.5,
                        // barThickness: 5.0,
                        barPercentage: 0.99,
                        borderRadius: 10
                    },],
            },
            this.chartTemp = {
                    labels: this.trendData1.temp_time,
    
                    datasets:[{
                        label:'기온',
                        data: this.trendData1.temp,
                        backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,180);

                        gradient.addColorStop(0,  "rgba(198, 252, 236, 0.2)");
                        gradient.addColorStop(.5, "rgba(198, 252, 236, 0.4)");
                        gradient.addColorStop(1, "rgba(198, 252, 236, 0)");

                        return gradient;
                        },
                        borderColor: '#00B9C1',
                    },],
            },
            this.chartHum = {
                labels: this.trendData1.hum_time,

                datasets:[{
                    label:'습도',
                    data: this.trendData1.hum,
                    borderColor: '#f9eb97',
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,130);

                        gradient.addColorStop(0,  "rgba(249, 235, 151, 0.5)");
                        gradient.addColorStop(.5, "rgba(249, 235, 151, 0.4)");
                        gradient.addColorStop(1, "rgba(249, 235, 151, 0)");

                        return gradient;
                    },
                },],
        },
        // this.chartPres = {
        //         labels: this.trendData1.pres_time,

        //         datasets:[{
        //             label:'기압',
        //             data: this.trendData1.pres,
        //             borderColor: '#a8d9f6',
        //             backgroundColor: (ctx) => {
        //                 const canvas = ctx.chart.ctx;
        //                 const gradient = canvas.createLinearGradient(0,0,0,150);

        //                 gradient.addColorStop(0,  "rgba(168, 217, 246, 0.5)");
        //                 gradient.addColorStop(.5, "rgba(168, 217, 246, 0.4)");
        //                 gradient.addColorStop(1, "rgba(168, 217, 246, 0)");

        //                 return gradient;
        //             },
        //         },],
        // },
        // this.chartWind = {
        //         labels: this.trendData1.wind_time,

        //         datasets:[{
        //             label:'풍속',
        //             data: this.trendData1.wind,
        //             borderColor: '#1a659e',
        //             backgroundColor: (ctx) => {
        //                 const canvas = ctx.chart.ctx;
        //                 const gradient = canvas.createLinearGradient(0,0,0,150);

        //                 gradient.addColorStop(0,  "rgba(26, 101, 158, 0.5)");
        //                 gradient.addColorStop(.5, "rgba(26, 101, 158, 0.4)");
        //                 gradient.addColorStop(1, "rgba(26, 101, 158, 0)");

        //                 return gradient;
        //             },
        //         },],
        //     },
            // 일별
            this.dailyChartWatt = {
                labels: this.trendData1.watt_time,
    
                datasets:[{
                    label:'전력량',
                    data: this.trendData1.watt_daily,
                    borderColor: '#F7F2A9',
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0,0,0,180);

                        gradient.addColorStop(0,  "rgba(243, 237, 145, 0.2)");
                        gradient.addColorStop(.5, "rgba(243, 237, 145, 0.4)");
                        gradient.addColorStop(1, "rgba(243, 237, 145, 0)");

                        return gradient;
                    },
                    borderWidth: 0.5,
                    barThickness: 30.0,
                    borderRadius: 20,
                },],
            },
            this.dailyChartTemp = {
                labels: this.trendData1.temp_time,
    
                datasets:[{
                    label:'평균',
                    data: this.trendData1.temp_avg,
                    backgroundColor: "rgba(198, 252, 236, 0.1)",
                    borderColor: '#00B9C1',
                },
                {
                    label:'최저',
                    data: this.trendData1.temp_low,
                    fill: false,
                    borderColor: "rgba(0, 0, 255, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최고',
                    data: this.trendData1.temp_high,
                    fill: false,
                    borderColor:"rgba(255, 0, 0, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },],
            },
            this.dailyChartHum = {
            labels: this.trendData1.hum_time,

            datasets:[{
                label:'평균',
                data: this.trendData1.hum_avg,
                borderColor: '#f9eb97',
                backgroundColor: "rgba(249, 235, 151, 0.1)",
            },
            {
                label:'최저',
                data: this.trendData1.hum_low,
                fill: false,
                borderColor: "rgba(93, 138, 168, 0.4)",
                tension: 0.1,
                borderDash: [3, 3],
            },
            {
                label:'최고',
                data: this.trendData1.hum_high,
                fill: false,
                borderColor:"rgba(124, 9, 2, 0.4)",
                tension: 0.1,
                borderDash: [3, 3],
            },],
        }
        // this.dailyChartPres = {
        //     labels: this.trendData1.pres_time,
        //     datasets:[{
        //         label:'평균',
        //         data: this.trendData1.pres_avg,
        //         borderColor: '#a8d9f6',
        //         backgroundColor: "rgba(168, 217, 246, 0.1)",
        //     },
        //     {
        //         label:'최저',
        //         data: this.trendData1.pres_low,
        //         fill: false,
        //         borderColor: "rgba(93, 138, 168, 0.4)",
        //         tension: 0.1,
        //         borderDash: [3, 3],
        //     },
        //     {
        //         label:'최고',
        //         data: this.trendData1.pres_high,
        //         fill: false,
        //         borderColor:"rgba(124, 9, 2, 0.4)",
        //         tension: 0.1,
        //         borderDash: [3, 3],
        //     },],
        // },
        // this.dailyChartWind = {
        //     labels: this.trendData1.wind_time,

        //     datasets:[{
        //         label:'평균',
        //         data: this.trendData1.wind_avg,
        //         borderColor: '#1a659e',
        //         backgroundColor: "rgba(26, 101, 158, 0.1)",
        //     },
        //     {
        //         label:'최저',
        //         data: this.trendData1.wind_low,
        //         fill: false,
        //         borderColor: "rgba(93, 138, 168, 0.4)",
        //         tension: 0.1,
        //         borderDash: [3, 3],
        //     },
        //     {
        //         label:'최고',
        //         fill: false,
        //         data: this.trendData1.wind_high,
        //         borderColor:"rgba(124, 9, 2, 0.4)",
        //         tension: 0.1,
        //         borderDash: [3, 3],
        //     },],
        // }
        }
    },
    mounted () {
    },
    methods:{
        resetGraph() {
            if(this.chartWatt){
                this.$refs.wattChart.getCurrentChart().resetZoom();
            }
            if(this.chartTemp){
                this.$refs.tempChart.getCurrentChart().resetZoom();
            }
        },
    },
    }
    </script>
        
<style lang="scss" scoped >
.chart_Layout{
    width:90vw;
    margin:10px 5px 20px 15px;
    padding-left:10px;
    padding-right:20px;
    background-color:white;
    border:1px solid #e5e5e5;
    border-radius: 20px;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);

}
.reset_btn{
    background-color:#3a4f5f;
    border-radius: 50%;
    width:25px;
    height:25px;
    line-height:15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}
.title{
    text-align:center;
    font-weight:600 !important;
    font-size:15px !important;
    margin-bottom: 5px;
    letter-spacing: -1px !important;
}
</style>