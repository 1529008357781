<template>
  <v-container>
    <v-layout>
      <v-card width="100%" height="auto" class="mx-auto" outlined color="transparent">
        <!-- 시 간격별 데이타 조회   -->
        <v-data-table  dense height="450"
          id="time__table"
          v-show="divisionTerm < 5"
          fixed-header
          :footer-props="{
                showFirstLastPage: true,
                disableItemsPerPage: true,
                itemsPerPageOptions: [300],
              }"
          :loading="loadingTable" 
          :headers="headers"
          :items="items"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          item-key="checkDateTime"
          single-select
          :sort-by= "['checkDate','checkTime']"
          :sort-desc= "[true,true]"
          :search="search"
          mobile-breakpoint="0"
        >
        </v-data-table>

        <!-- 일별 데이타 조회   -->
        <v-data-table  dense height="450" 
          id="daily__table"
          v-show="divisionTerm > 4"
          fixed-header
          hide-default-footer
          :items-per-page= "1000"
          :loading="loadingTable" 
          :headers="headers_daily"
          :items="items_daily"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          item-key="checkDate"
          single-select
          sort-by= "checkDate"
          :sort-desc= "true"
          :search="search"
          mobile-breakpoint="0"
          >
        </v-data-table>

        <v-card-title>
          <v-text-field dense style="width:220px" class="shrink"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="날짜 또는 시간으로 골라보기"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// import Apis from '@/api/apis'
// import Common from "@/utils/custom/common.js";

export default {
  name: 'WattList',
  props: {
    items: {
      type: Array,
    },
    items_daily: {
      type: Array,
    },
    loadingTable: Boolean,
    divisionTerm: Number,
  },

  created() {
  },
  
  data() {
    return {
      search: "",
      headers: [
        { text: '측정일', align: 'center', value: 'checkDate',filterable: true, sortable: true, groupable: false, width: "115px",},
        { text: '측정시간', align: 'center', value: 'checkTime', filterable: true, sortable: true, groupable: false, width: "90px",divider: true },
        { text: '전력', align: 'center', value: 'watt_sum', filterable: false, sortable: false, groupable: false, width: "55px",divider: true },
        
        { text: '기온', align: 'center', value: 'w_temp', filterable: false, sortable: false, groupable: false, width: "55px",divider: true },
        { text: '습도', align: 'center', value: 'w_hum', filterable: false, sortable: false, groupable: false, width: "55px", divider: true },
        // { text: '기압 hPa', align: 'center', value: 'w_ps', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
        // { text: '풍속 m/s', align: 'center', value: 'w_ws', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
      ],

      headers_daily: [
        { text: '측정일', align: 'center', value: 'checkDate', filterable: true, sortable: false, groupable: false, width: "112px", divider: true },
        
        { lb: true, text0: '사용전력량 kw',text: '최고', align: 'center', value: 'watt_highest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text0: '',text: '최저', align: 'center', value: 'watt_lowest', filterable: false, sortable: false, groupable: false, width: "60px",  divider: false },
        { text: '전력합계', align: 'center', value: 'watt_sum', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '기온 ℃',text: '최고', align: 'center', value: 'w_temp_highest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text0: '',text: '최저', align: 'center', value: 'w_temp_lowest', filterable: false, sortable: false, groupable: false, width: "60px",  divider: false },
        { text: '평균기온', align: 'center', value: 'w_temp_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '습도 %',text: '최고', align: 'end', value: 'w_hum_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text0: '',text: '최저', align: 'end', value: 'w_hum_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text: '평균습도', align: 'end', value: 'w_hum_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        // { lb: true, text0: '기압 hPa',text: '최고', align: 'end', value: 'w_ps_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        // { text0: '',text: '최저', align: 'end', value: 'w_ps_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        // { text: '평균', align: 'end', value: 'w_ps_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        // { lb: true, text0: '풍속 m/s',text: '최고', align: 'end', value: 'w_ws_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        // { text0: '',text: '최저', align: 'end', value: 'w_ws_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        // { text: '평균', align: 'end', value: 'w_ws_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },
      
      ],
    }
  },
  methods: {
  },  // -----------methods 
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #e4e8d4 !important;
      color: #3a4f3a !important;
      font-size:13px;
      letter-spacing: -1px;
      height:40px !important;
    }
  }
  tbody td {
    font-size: 14.5px !important;
    letter-spacing: -0.3px;
  }
  tbody tr{
    height:40px;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .03);
  }

}

.cell_center, .cell_bottom, .cell_top{
  text-align:center !important;
}

</style>

